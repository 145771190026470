import enumConfigs from "@/mixins/enumConfigs"
import configMixins from "@/mixins/config"
import { Weixin } from '@/utils/wx-jssdk.js'
import * as funCommon from "@/api/common"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"

export default {
    mixins: [enumConfigs, configMixins],
    methods: {
        async getAllCompany() {
            await funInfomation.searchCompany().then(res => {
                this.allCompany = res.items
            })
        },
        async getAllPrincipal() {
            await funInfomation.searchEmployees().then(res => {
                this.allPrincipal = this.sortForAssetOrPrincipal(res.items, this.dialogData.companyId, "员工")
            })
        },
        async getAllAssetUser() {
            await funInfomation.searchAssetUser().then(res => {
                this.allAssetUser = this.sortForAssetOrPrincipal(res.items, this.dialogData.companyId, "服务区")
            })
        },
        changeCompany() {
            this.dialogData.assetUserId = ""
            this.getAllAssetUser()
        },
        scanBarcode() {
            this.scanCodeType = 1
            this.$nextTick(() => {
                // 移动端不自动聚焦
                if (!window.$util.isMobileDevice()) {
                    this.$refs[this.refAutoComplete].focus()
                }
                this.openBarcodeHtmlScan()
            })
        },
        scanQrCode() {
            this.scanCodeType = 2
            this.openScanCode()
        },
        querySearchAsync(queryString, cb) {
            // 查询商品
            var restaurants = []
            if (!queryString) {
                cb(restaurants)
                return false
            }
            var query = {
                categoryId: null,
                companyId: "",
                ownerType: this.valOwnerWarehouse() || null,
                ownerId: this.dialogData.warehouseId || "",
                keywords: queryString,
                maxResultCount: 20
            }
            funStock.getStockSearch(query).then(res => {
                for (var i in res) {
                    var item = res[i]
                    var temp = {}
                    temp.id = item.id
                    temp.value = item.name
                    temp.barCode = item.barCode
                    temp.imageUrl = item.imageUrl || ""
                    temp.needSerialNumber = item.hasSerialNumber
                    temp.category = item.category
                    temp.maxCount = item.stock
                    restaurants.push(temp)
                }
                cb(restaurants)
            }).catch(e => {
                this.$alert('资产加载出错!')
                return false
            })
        },
        execSelect(callback) {
            // 扫码自动识别
            let obj = {}
            let searchDatas = []
            if (this.keyword) {
                var query = {
                    categoryId: null,
                    companyId: "",
                    ownerType: this.valOwnerWarehouse() || null,
                    ownerId: this.dialogData.warehouseId || "",
                    keywords: this.keyword,
                    maxResultCount: 20
                }
                funStock.getStockSearch(query).then(res => {
                    for (var i in res) {
                        var item = res[i]
                        var temp = {}
                        temp.id = item.id
                        temp.value = item.name
                        temp.barCode = item.barCode
                        temp.imageUrl = item.imageUrl || ""
                        temp.needSerialNumber = item.hasSerialNumber
                        temp.category = item.category
                        temp.maxCount = item.stock
                        searchDatas.push(temp)
                    }
                    if (searchDatas.length == 1) {
                        obj = searchDatas[0]
                        this.handleSelect(obj, callback)
                        callback && callback(res[0])
                    } else if (searchDatas.length > 1) {
                        this.warningMsg("资产选项不止一个，请手动选择!")
                        return false
                    } else {
                        this.errorMsg("不存在当前资产信息!")
                        return false
                    }
                }).catch(e => {
                    this.$alert('资产加载出错!')
                    return false
                })
            }
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0)
            }
        },
        handleSelect(item, callback) {
            this.clearAutoComplete()
            if (this.dialogData.warehouseId && item.maxCount === 0) {
                this.warningMsg(`${item.value}，库存不足!`)
                return false
            }
            // 重复资产不用添加
            let asset = this.dialogData.items.find(x => {
                return x.assetId == item.id
            })
            if (asset) {
                if (!callback) {
                    this.warningMsg(`${asset.assetName}，已存在资产列表!`)
                }
                return false
            }
            let temp = {}
            temp.assetId = item.id
            temp.assetName = item.value // item.value
            temp.barCode = item.barCode
            temp.needSerialNumber = item.needSerialNumber
            temp.serialNumbers = item.serialNumbers || []
            temp.lastSerialNumbers = item.serialNumbers || []
            temp.count = temp.needSerialNumber ? temp.serialNumbers.length : 1
            temp.imageUrl = item.imageUrl
            temp.category = item.category
            temp.maxCount = item.maxCount
            temp.selectNumbers = []
            this.dialogData.items.push(temp)
        },
        // 负责人
        querySearchPrincipal(queryString, cb) {
            var restaurants = this.allPrincipal.map(x => {
                let temp = {
                    name: x.name,
                    value: x.name
                }
                return temp
            })
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
            cb(results)
        },
        _delete(row, index) {
            this.confirm("确定要删除当前数据吗？").then(() => {
                this.dialogData.items.splice(index, 1)
            })
        },
        // 多图上传成功
        setImageUrls(val) {
            if (val[2]) {
                this.dialogData.attachments = JSON.parse(JSON.stringify(val[2]))
                return false
            }
            if (val[0] >= 0) {
                if (!val[1]) {
                    this.dialogData.attachments.splice(val[0], 1)
                } else {
                    this.dialogData.attachments.splice(val[0], 1, val[1])
                }
            } else {
                this.dialogData.attachments.push(val[1])
            }
        },
        clearAutoComplete() {
            document.activeElement.blur()
            this.keyword = ""
            this.$refs[this.refAutoComplete].getData("")
            this.$refs[this.refAutoComplete].focus()
        },
        openScanCode(row) {
            this.$refs[this.refToScanSerial].open({ ownerId: this.dialogData.warehouseId, ownerType: this.valOwnerWarehouse() }, row)
        },
        setAssetDatas(datas, curProduct) {
            // 有产品的情况下，如果扫码数据为空
            if (curProduct && datas.length <= 0) {
                let assetIndex = this.dialogData.items.findIndex(x => {
                    return x.assetId == curProduct.assetId
                })
                if (assetIndex != -1) {
                    let tempItem = JSON.parse(JSON.stringify(this.dialogData.items[assetIndex]))
                    tempItem.serialNumbers = []
                    tempItem.count = tempItem.serialNumbers.length
                    this.dialogData.items.splice(assetIndex, 1, tempItem)
                }
                return false
            }
            // 扫码回传数据进行合并数据
            for (let i in datas) {
                let item = datas[i]
                // 判断当前数据是否在商品中存在
                let assetIndex = this.dialogData.items.findIndex(x => {
                    return x.assetId == item.assetId
                })
                // 存在则先判断资产是否相同
                if (assetIndex != -1) {
                    let tempItem = JSON.parse(JSON.stringify(this.dialogData.items[assetIndex]))
                    // 如果是通过产品进入的扫码，回来就
                    if (curProduct) {
                        tempItem.serialNumbers = []
                    }
                    datas.forEach((tp) => {
                        if (tp.assetId == item.assetId && tempItem.serialNumbers.indexOf(tp.serialNumber) === -1) {
                            tempItem.serialNumbers.push(tp.serialNumber)
                        }
                    })
                    tempItem.count = tempItem.serialNumbers.length
                    this.dialogData.items.splice(assetIndex, 1, tempItem)
                } else {
                    item.serialNumbers = [item.serialNumber]
                    item.count = item.serialNumbers.length
                    this.dialogData.items.push(item)
                }
            }
        },
        async getWechatSign() {
            await funCommon.GetWechatTicket().then(res => {
                let jssdkRes = res
                let wxJS = new Weixin()
                wxJS.init({
                    appId: jssdkRes.appId, // 必填，公众号的唯一标识
                    timestamp: jssdkRes.timestamp, // 必填，生成签名的时间戳
                    nonceStr: jssdkRes.nonceStr, // 必填，生成签名的随机串
                    signature: jssdkRes.signature // 必填，签名
                })
                wxJS.scanQRCode(wres => {
                    let arr = wxJS.tranceBarcode(wres.resultStr)
                    this.htmlScanSuccess([arr[0]])
                })
            })
        },
        // 打开条形码扫码
        openBarcodeHtmlScan() {
            let browser = window.$util.getBrowser()
            if (browser && browser.wechat) {
                this.getWechatSign()
            } else {
                if (!this.$root.isPc) {
                    this.$refs[this.refHtmlScan].open()
                }
            }
        },
        // 调用摄像头扫码成功
        htmlScanSuccess(arr) {
            this.keyword = arr[0]
            this.execSelect()
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.successMsg(tips, {
                onClose: () => {
                    this._close()
                    this.refreshData(data)
                }
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}


